.header {
  width: 100%;
  height: 905px;
  background: url("../../../img/header_bg.jpg") 50% 100% no-repeat;
  background-size: cover;
}
.header__number {
  position: absolute;
  top: 50px;
  right: 0;
  color: #fff;
  font-size: 46px;
  text-decoration: none;
  line-height: 40px;
  border-bottom: 2px solid #fff;
  transition: 0.3s;
}
.header__number:hover {
  border-bottom: 2px solid transparent;
}
.header__number:active {
  opacity: 0.7;
}
.header__number:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  left: -65px;
  top: 50%;
  transform: translate(0, -50%);
  background: url("../../../img/phone.png") 50% 50% no-repeat;
  background-size: 75%;
  border: 4px solid #fff;
  border-radius: 50%;
}
.header__logo {
  position: absolute;
  top: 170px;
  right: 50px;
  width: 315px;
  height: 190px;
  object-fit: contain;
}
@media (max-width: 1500px) {
  .header {
    height: 700px;
  }
  .header__number:before {
    left: -70px;
    top: 50%;
  }
  .header__logo {
    right: 65px;
  }
}
@media (max-width: 1250px) {
  .header__number {
    right: 50px;
  }
  .header__logo {
    right: 100px;
  }
}
@media (max-width: 900px) {
  .header {
    height: 525px;
  }
  .header__number {
    font-size: 36px;
    text-decoration: none;
    line-height: 40px;
  }
  .header:before {
    width: 50px;
    height: 50px;
  }
  .header__logo {
    top: 140px;
    width: 220px;
    height: 130px;
  }
}
@media (max-width: 700px) {
  .header {
    height: 50vw;
  }
  .header__number {
    top: 30px;
    font-size: 26px;
    text-decoration: none;
    line-height: 25px;
    border-bottom: 1px solid #fff;
  }
  .header__number:before {
    width: 30px;
    height: 30px;
    left: -40px;
    border: 2px solid #fff;
  }
  .header__logo {
    top: 80px;
    width: 120px;
    height: 80px;
  }
}
@media (max-width: 500px) {
  .header__number {
    top: 10px;
    right: 15px;
    font-size: 20px;
    line-height: 20px;
  }
  .header__number:before {
    width: 25px;
    height: 25px;
    left: -35px;
  }
  .header__logo {
    top: 40px;
    right: 55px;
    width: 80px;
    height: 40px;
  }
}
.footer {
  height: 210px;
  background: url("../img/footer_bg.jpg") 50% 50% no-repeat;
  background-size: cover;
  box-sizing: border-box;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.footer__link {
  color: #e9ca6c;
  font-size: 31px;
  text-decoration: none;
  line-height: 25px;
  border-bottom: 2px solid #e9ca6c;
  transition: 0.3s;
}
.footer__link:hover {
  border-bottom: 2px solid transparent;
}
.footer__link:active {
  opacity: 0.7;
}
.footer__text {
  color: #fff;
  font-size: 31px;
}
.footer__number {
  position: relative;
  color: #fff;
  background: #e2000f;
  margin-left: 90px;
  padding: 0 20px;
  font-size: 46px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 0px 15px 15px 0px;
  z-index: 2;
  transition: 0.3s;
}
.footer__number:hover {
  transform: scale(0.95);
}
.footer__number:active {
  opacity: 0.7;
}
.footer__number:before {
  content: "";
  position: absolute;
  width: 65px;
  height: 65px;
  left: -85px;
  top: 50%;
  transform: translate(0, -50%);
  background: #e2000f url("../../../img/phone.png") 50% 50% no-repeat;
  background-size: 50px 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, 0 0 0 10px #e2000f;
  z-index: 2;
}
.footer__number:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  background: #e2000f;
  width: 20px;
  height: 54px;
  transform: translate(0, -50%);
  box-shadow: inset -5px 0 15px -10px #000;
}
@media (max-width: 1250px) {
  .footer .container {
    margin: 0 50px;
  }
  .footer__link {
    font-size: 25px;
    line-height: 20px;
  }
  .footer__text {
    font-size: 26px;
  }
  .footer__number {
    font-size: 32px;
  }
  .footer__number:before {
    width: 55px;
    height: 55px;
    left: -75px;
    background-size: 40px 40px;
  }
  .footer__number:after {
    height: 37px;
  }
}
@media (max-width: 1000px) {
  .footer {
    height: 150px;
  }
  .footer__link {
    font-size: 23px;
    line-height: 20px;
  }
  .footer__text {
    font-size: 20px;
  }
  .footer__number {
    font-size: 27px;
  }
  .footer__number:before {
    width: 40px;
    height: 40px;
    left: -55px;
    background-size: 30px 30px;
    box-shadow: 0 0 0 3px #fff, 0 0 0 8px #e2000f;
  }
  .footer__number:after {
    height: 32px;
  }
}
@media (max-width: 860px) {
  .footer {
    height: 250px;
  }
  .footer .container {
    padding: 50px 0;
    flex-direction: column;
    box-sizing: border-box;
  }
  .footer .container > * {
    display: block;
    text-align: center;
  }
  .footer__text {
    font-size: 24px;
  }
  .footer__number {
    margin-left: 35px;
  }
}
@media (max-width: 500px) {
  .footer {
    height: 200px;
  }
  .footer .container {
    padding: 40px 0;
    flex-direction: column;
    box-sizing: border-box;
  }
  .footer .container > * {
    display: block;
    text-align: center;
  }
  .footer__text {
    font-size: 16px;
  }
  .footer__link {
    font-size: 18px;
    line-height: 16px;
    border-bottom: 1px solid #e9ca6c;
  }
  .footer__number {
    margin-left: 35px;
    font-size: 20px;
  }
  .footer__number:before {
    width: 30px;
    height: 30px;
    left: -45px;
    background-size: 20px 20px;
    box-shadow: 0 0 0 3px #fff, 0 0 0 8px #e2000f;
  }
  .footer__number:after {
    height: 23px;
  }
}
.card {
  position: relative;
  padding-top: 70px;
  padding-bottom: 80px;
  color: #8a6542;
}
.card__content {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
}
.card__title {
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
  letter-spacing: 2px;
}
.card__sub-title {
  font-size: 33px;
}
.card__text {
  font-size: 21px;
  font-style: italic;
  line-height: 33px;
}
.card__text_red {
  color: #e2000f;
}
.card__row {
  height: 55px;
  box-sizing: border-box;
}
.card__cell {
  font-size: 31px;
}
.card__cell:first-of-type {
  display: flex;
  justify-content: space-between;
  max-width: 580px;
  min-width: 450px;
  padding: 0 10px;
  padding-bottom: 3px;
  background: #ededed;
  border-radius: 10px;
  box-sizing: border-box;
}
.card__cell:last-of-type {
  vertical-align: top;
  padding-left: 20px;
}
.card__table-text_red {
  color: #e2000f;
}
.card_gray {
  background: url("../img/card_bg.jpg") 50% 50% no-repeat;
  background-size: cover;
}
.card_gray .card__content {
  margin-left: auto;
}
.card_gray .card__cell:first-of-type {
  background: #fff !important;
}
.card:nth-of-type(1) {
  padding-bottom: 120px;
  z-index: -2;
}
.card:nth-of-type(1) .card__title {
  margin-bottom: 110px;
}
.card:nth-of-type(1) .card__cell:first-of-type {
  width: 580px;
}
.card:nth-of-type(2) {
  padding-bottom: 152px;
}
.card:nth-of-type(2) .card__cell:first-of-type {
  width: 590px;
}
.card:nth-of-type(2) .card__title {
  margin-top: 20px;
}
.card:nth-of-type(3) {
  padding-bottom: 200px;
}
.card:nth-of-type(3) .card__content {
  padding-left: 20px;
  padding-right: 100px;
}
.card:nth-of-type(3) .card__text {
  margin-bottom: 70px;
}
.card:nth-of-type(4) {
  padding-bottom: 140px;
}
.card:nth-of-type(4) .card__content {
  padding-left: 150px;
  width: 53%;
}
.card:nth-of-type(4) .card__text,
.card:nth-of-type(4) .card__sub-title {
  padding-top: 25px;
}
.card:nth-of-type(5) {
  padding-bottom: 130px;
  z-index: -2;
}
.card:nth-of-type(5) .card__content {
  padding-right: 220px;
  padding-left: 20px;
}
.card:nth-of-type(5) .card__title {
  margin-bottom: 30px;
}
.card:nth-of-type(5) .card__text {
  margin-bottom: 15px;
}
@media (max-width: 1500px) {
  .card:nth-of-type(1),
  .card:nth-of-type(2) {
    padding-top: 50px;
  }
  .card:nth-of-type(1) .card__cell:first-of-type,
  .card:nth-of-type(2) .card__cell:first-of-type {
    width: 0;
    max-width: 580px;
  }
  .card:nth-of-type(1) {
    padding-top: 70px;
  }
  .card:nth-of-type(1) .card__title {
    margin-bottom: 50px;
  }
  .card:nth-of-type(2) {
    padding-bottom: 50px;
  }
  .card:nth-of-type(2) .card__title {
    margin-bottom: 40px;
  }
  .card:nth-of-type(2) .card__cell:first-of-type {
    min-width: 420px;
  }
  .card:nth-of-type(3) {
    padding-bottom: 150px;
  }
  .card:nth-of-type(3) .card__content {
    padding-right: 0;
  }
  .card:nth-of-type(3) .card__text {
    margin-bottom: 30px;
  }
  .card:nth-of-type(4) .card__content {
    padding-left: 0;
  }
  .card:nth-of-type(4) .card__sub-title {
    padding-top: 40px;
  }
  .card:nth-of-type(5) {
    padding-bottom: 60px;
  }
  .card:nth-of-type(5) .card__content {
    padding-right: 90px;
  }
}
@media (max-width: 1260px) {
  .card {
    padding-bottom: 100px !important;
    padding-top: 50px;
  }
  .card__content {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
  }
  .card__title {
    font-size: 40px;
    margin-bottom: 50px !important;
  }
  .card__sub-title {
    font-size: 28px;
  }
  .card__text {
    font-size: 18px;
  }
  .card__row {
    height: 55px;
    box-sizing: border-box;
  }
  .card__cell {
    font-size: 25px;
  }
  .card__cell:first-of-type {
    max-width: 480px;
    min-width: 400px;
  }
}
@media (max-width: 1050px) {
  .card__content {
    width: 100%;
  }
  .card__content > * {
    margin: 0 auto;
  }
  .card__text {
    width: 600px;
  }
  .card:nth-of-type(4) .card__content {
    padding-left: 100px;
  }
  .card:nth-of-type(4) .card__text {
    padding-top: 10px;
  }
  .card:nth-of-type(5) .card__content {
    padding-right: 0;
  }
}
@media (max-width: 750px) {
  .card {
    padding-bottom: 50px !important;
  }
  .card__title {
    font-size: 28px;
    margin-bottom: 30px !important;
  }
  .card__sub-title {
    font-size: 20px;
    width: 460px;
  }
  .card__text {
    width: 460px;
    font-size: 16px;
    line-height: 25px;
  }
  .card__row {
    height: 55px;
    box-sizing: border-box;
  }
  .card__cell {
    font-size: 20px;
  }
  .card__cell:first-of-type {
    max-width: 400px;
    min-width: 320px;
  }
  .card__row {
    height: 40px;
  }
  .card:nth-of-type(2) .card__cell:first-of-type {
    min-width: 320px;
  }
  .card:nth-of-type(3) .card__content {
    padding: 0;
  }
  .card:nth-of-type(3) .card__text {
    margin-bottom: 30px;
  }
  .card:nth-of-type(4) .card__content {
    width: 100%;
    padding-left: 0;
  }
  .card:nth-of-type(4) .card__text {
    margin: 0 auto;
    padding-top: 10px;
  }
  .card:nth-of-type(5) .card__text {
    text-align: center;
    margin-bottom: 10px;
  }
  .card:nth-of-type(5) .card__table {
    padding-left: 20px;
  }
}
@media (max-width: 470px) {
  .card {
    padding: 30px 0;
    padding-bottom: 30px !important;
  }
  .card__title {
    font-size: 22px;
    margin-bottom: 20px !important;
  }
  .card__text {
    width: 90%;
    font-size: 13px;
    line-height: 23px;
  }
  .card__sub-title {
    font-size: 16px;
    width: 90%;
  }
  .card__row {
    height: 55px;
    box-sizing: border-box;
  }
  .card__cell {
    font-size: 15px;
  }
  .card__cell:first-of-type {
    max-width: 340px;
    min-width: 230px;
  }
  .card__row {
    height: 30px;
  }
  .card:nth-of-type(2) .card__cell:first-of-type {
    min-width: 230px;
  }
  .card:nth-of-type(3) .card__cell:first-of-type {
    min-width: 200px;
  }
  .card:nth-of-type(4) .card__content {
    padding: 0;
  }
  .card:nth-of-type(4) .card__sub-title {
    font-size: 16px;
    padding-top: 10px;
  }
}
.card__decor {
  position: absolute;
  top: 0;
  left: 0;
}
.card__decor:before,
.card__decor:after {
  content: "";
  position: absolute;
}
.card:nth-of-type(1) .card__decor:before {
  top: -60px;
  left: -150px;
  height: 400px;
  width: 390px;
  background: url("../../img/first-card1.png") 50% 50% no-repeat;
  background-size: contain;
  z-index: 2;
}
.card:nth-of-type(1) .card__decor:after {
  top: 150px;
  left: -50px;
  height: 520px;
  width: 790px;
  background: url("../../img/first-card2.png") 50% 50% no-repeat;
  background-size: contain;
}
.card:nth-of-type(2) .card__decor:before {
  top: 40px;
  left: 730px;
  height: 600px;
  width: 800px;
  background: url("../../img/second-card1.png") 50% 50% no-repeat;
  background-size: contain;
}
.card:nth-of-type(3) .card__decor {
  top: -100px;
  left: -380px;
  height: 310px;
  width: 575px;
  background: url("../../img/third-card1.png") 50% 50% no-repeat;
  background-size: contain;
}
.card:nth-of-type(3) .card__decor:before {
  top: 230px;
  left: 450px;
  height: 440px;
  width: 610px;
  background: url("../../img/third-card2.png") 50% 50% no-repeat;
  background-size: contain;
}
.card:nth-of-type(3) .card__decor:after {
  top: -100px;
  left: 1550px;
  height: 520px;
  width: 790px;
  background: url("../../img/third-card3.png") 50% 50% no-repeat;
  background-size: contain;
}
.card:nth-of-type(4) .card__decor:before {
  top: -230px;
  left: -270px;
  height: 440px;
  width: 570px;
  background: url("../../img/fourth-card1.1.png") 50% 50% no-repeat;
  background-size: contain;
}
.card:nth-of-type(4) .card__decor:after {
  top: 0px;
  left: 830px;
  height: 615px;
  width: 690px;
  background: url("../../img/fourth-card2.png") 50% 50% no-repeat;
  background-size: contain;
  z-index: 2;
}
.card:nth-of-type(5) .card__decor:before {
  top: -230px;
  left: 0px;
  height: 470px;
  width: 500px;
  background: url("../../img/fifth-card1.png") 50% 50% no-repeat;
  background-size: contain;
}
.card:nth-of-type(5) .card__decor:after {
  top: 160px;
  left: -120px;
  height: 550px;
  width: 830px;
  background: url("../../img/fifth-card2.png") 50% 50% no-repeat;
  background-size: contain;
  z-index: -1;
}
@media (max-width: 1670px) {
  .card:nth-of-type(3) .card__decor {
    top: -50px;
    left: -200px;
  }
  .card:nth-of-type(3) .card__decor:before {
    top: 170px;
    left: 290px;
  }
  .card:nth-of-type(3) .card__decor:after {
    top: -100px;
    left: 1200px;
  }
  .card:nth-of-type(4) .card__decor:before {
    top: -170px;
    left: -130px;
    height: 440px;
    width: 445px;
    background: url("../../img/fourth-card1.2.png") 50% 50% no-repeat;
    background-size: contain;
  }
  .card:nth-of-type(4) .card__decor:after {
    top: -20px;
    left: 750px;
  }
}
@media (max-width: 1500px) {
  .card:nth-of-type(1) .card__decor:before {
    top: -130px;
    left: -200px;
  }
  .card:nth-of-type(1) .card__decor:after {
    left: -150px;
    top: 90px;
    z-index: -1;
  }
  .card:nth-of-type(2) .card__decor:before {
    top: -40px;
    left: 570px;
  }
  .card:nth-of-type(3) .card__decor {
    top: -50px;
    left: -300px;
  }
  .card:nth-of-type(3) .card__decor:before {
    top: 130px;
    left: 230px;
    height: 540px;
    width: 650px;
  }
  .card:nth-of-type(3) .card__decor:after {
    top: -150px;
    left: 1150px;
  }
  .card:nth-of-type(4) .card__decor:before {
    top: -200px;
    left: -230px;
    height: 460px;
    width: 465px;
  }
  .card:nth-of-type(4) .card__decor:after {
    top: -20px;
    left: 680px;
    height: 630px;
    width: 700px;
  }
  .card:nth-of-type(5) .card__decor:before {
    top: -260px;
    left: -130px;
  }
  .card:nth-of-type(5) .card__decor:after {
    top: 160px;
    left: -240px;
  }
}
@media (max-width: 1260px) {
  .card:nth-of-type(1) .card__decor {
    left: -100px;
    top: -50px;
  }
  .card:nth-of-type(1) .card__decor:before {
    top: -100px;
    left: -100px;
  }
  .card:nth-of-type(2) .card__decor:before {
    top: 50px;
    left: 500px;
  }
  .card:nth-of-type(3) .card__decor {
    height: 310px;
    width: 425px;
  }
  .card:nth-of-type(3) .card__decor:before {
    top: 170px;
    left: 190px;
    height: 310px;
    width: 575px;
  }
  .card:nth-of-type(3) .card__decor:after {
    display: none;
  }
  .card:nth-of-type(4) .card__decor:after {
    top: -20px;
    left: 570px;
  }
  .card:nth-of-type(5) .card__decor {
    left: -80px;
  }
}
@media (max-width: 1050px) {
  .card:nth-of-type(1) .card__decor:before {
    top: 0px;
    left: -40px;
    width: 40vw;
  }
  .card:nth-of-type(1) .card__decor:after {
    display: none;
  }
  .card:nth-of-type(3) .card__decor {
    top: -100px;
    left: -130px;
    width: 40vw;
  }
  .card:nth-of-type(4) .card__decor:before {
    top: 130px;
    left: 56vw;
    width: 40vw;
    transform: rotate(20deg);
  }
  .card:nth-of-type(1) .card__decor:after,
  .card:nth-of-type(2) .card__decor:before,
  .card:nth-of-type(3) .card__decor:before,
  .card:nth-of-type(4) .card__decor:after,
  .card:nth-of-type(5) .card__decor {
    display: none;
  }
}
@media (max-width: 750px) {
  .card__decor {
    display: none;
  }
}
body {
  font-family: Cambria, OpenType, TrueType;
  font-weight: bold;
}
.wrapper {
  overflow: hidden;
}
.container {
  position: relative;
  max-width: 1420px;
  margin: 0 auto;
}
@media (max-width: 1500px) {
  .container {
    max-width: 1180px;
  }
}
@media (max-width: 1250px) {
  .container {
    max-width: 990px;
  }
}
@font-face {
  font-weight: normal;
  font-family: Cambria, OpenType, TrueType;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/CAMBRIA_3.TTF") format("TTF");
}
@font-face {
  font-weight: bold;
  font-family: Cambria, OpenType, TrueType;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/CAMBRIAB_3.TTF") format("TTF");
}
@font-face {
  font-weight: normal;
  font-family: Cambria, OpenType, TrueType;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/CAMBRIAI_3.TTF") format("TTF");
}
@font-face {
  font-weight: bold;
  font-family: Cambria, OpenType, TrueType;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/CAMBRIAZ_3.TTF") format("TTF");
}
* {
  margin: 0;
  padding: 0;
}
